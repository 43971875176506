import React, { useState, useEffect, useContext } from 'react';
import { toDecimalPlaceWithCommas } from './utils';
import { useAppSelector } from 'store/hooks';
import { StoreContext } from 'context';
import { convertToNumber } from 'Utils';
import { errorStyle } from 'constants/globalStyles';

interface Props {
  value: number;
  setter: any;
  className: string;
  placeholder?: string;
}
const addCommas = (x: number) => x.toLocaleString();
const removeCommas = (x: string = '0') => {
  let val = parseFloat(x.replaceAll(/[,]/g, '')) || 0;
  let valToTwoDp = val.toFixed(2);
  return parseFloat(valToTwoDp);
};
const NumberInput = ({ value, className, setter, placeholder }: Props) => {
  const [sValue, setSValue] = useState<any>(addCommas(value));
  const { selectedProject } = useContext(StoreContext);
  useEffect(() => {
    if (sValue) {
      let str = removeCommas(sValue);

      setter(removeCommas(sValue));
    }
  }, [sValue]);
  useEffect(() => {
    setSValue((_: any) => addCommas(value));
  }, [value]);

  const _handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (val === '') {
      setSValue('0');
      return;
    }
    let notString = /[^0-9.,]/i.test(val);
    if (notString) {
      return;
    }
    if (val[val.length - 1] === '.' && val[val.length - 2] !== '.') {
      setSValue(val);
      return;
    }
    if (val.endsWith('0') && /\.[\d]{1,}/i.test(val)) {
      setSValue(val);
      return;
    }

    let num = convertToNumber(val);
    if (!isNaN(num)) {
      let formatedValue = new Intl.NumberFormat('en-US').format(num);
      setSValue(formatedValue);
    }
  };
  return (
    <>
      <div onClick={() => {}} className={className}>
        <div className="flex">
          <span className="mr-0.5 text-ashShade-2">{selectedProject?.currency?.code}</span>
          <input
            placeholder={placeholder}
            className={' border-0 flex-1 outline-none'}
            // pattern="/[0-9\,\.]+/"
            value={sValue}
            onChange={(e) => {
              e.currentTarget.setCustomValidity('');
              e.preventDefault();
              _handleNumberChange(e);
            }}
          />
        </div>
      </div>
    </>
  );
};

export const NumberInputWithLabel = ({
  value,
  setter,
  className,
  label,
  labelClassName,
  placeholder,
  error,
  containerClass
}: Props & {
  label?: string;
  labelClassName?: string;
  error?: string;
  containerClass?: string;
}) => {
  return (
    <div className={containerClass}>
      <p className={`  text-bash font-Medium ${labelClassName}`}>{label}</p>
      <NumberInput {...{ className, setter, value, placeholder, error }} />
      {error && <p className={errorStyle}>{error}</p>}
    </div>
  );
};

export default NumberInput;
