import { uploadFile, uploadWithOptions, uploadWithoutSaving } from 'Hooks/useProjectImages';
import { displayInfo, displaySuccess, displayWarning } from 'Utils';
import { postForm } from 'apis/postForm';
import Button from 'components/shared/Button';
import InputField from 'components/shared/InputField';
import { DocSelectBox } from 'components/shared/SelectBox';
import { StoreContext } from 'context';
import { useFormik } from 'formik';
import React, { ChangeEvent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { TbEdit, TbPhotoFilled, TbPlus } from 'react-icons/tb';
import { useAppDispatch } from 'store/hooks';
import { addCategories, updateCategories } from 'store/slices/tutorials';
import { isNullishCoalesce } from 'typescript';
import * as yup from 'yup';
import { TutorialCategoryType } from '../types';
import { _getFileUrl, getFileUrl } from 'components/shared/utils';
import useFiles from 'components/projects/documents/document-repo/useFiles';
interface Props {
  closer: () => void;
  onSuccess: () => void;
  isEditing?: boolean;
  value?: TutorialCategoryType;
}

const CategoryForm = ({ closer, onSuccess, isEditing, value }: Props) => {
  const [loading, setLoading] = useState(false);
  const { data: allData } = useContext(StoreContext);
  const [currentTHumbnail, setCurrentTHumbnail] = useState(value?.categoryImage);
  let dispatch = useAppDispatch();

  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  let { values, touched, setFieldValue, handleSubmit, handleChange, isValid } = useFormik({
    initialValues: {
      name: value?.name ?? '',
      description: value?.description ?? ''
    },
    onSubmit: (data) => {
      if (!file && !currentTHumbnail) {
        displayInfo('Please upload Category Thumbnail');
        return;
      }
      submit(data);
    },
    validationSchema: yup.object({
      name: yup.string().required().min(1, 'Please enter a valid name'),
      description: yup.string().notRequired()
    })
  });
  const convertFiletoUrl = (file: File) => {
    return URL.createObjectURL(file);
  };
  const submit = async (data: { name: string; description: string }) => {
    setLoading(true);
    let fileObject: any;
    if (file) {
      const proj = allData[0]?._id;

      let { e, response, file: _file } = await uploadWithoutSaving(file);
      if (response) {
        fileObject = response;
      }
    }

    let body = { ...data } as typeof data & {
      categoryImage: {
        meta: { name: string; type: string };
        key: string;
      };
      categoryId?: string;
    };
    if (fileObject) {
      let categoryImage = {
        key: fileObject?.S3Key,
        meta: {
          name: fileObject?.alias,
          type: fileObject.ContentType
        }
      };
      body['categoryImage'] = categoryImage;
    }
    if (isEditing) {
      body.categoryId = value?._id;
    }
    let { e, response } = await postForm(
      isEditing ? 'patch' : 'post',
      `learning-materials/category/${isEditing ? 'update' : 'create'}`,
      body,
      'iam'
    );

    if (response) {
      displaySuccess(isEditing ? 'Changes Saved' : 'Category Created');
      dispatch(
        !isEditing ? addCategories(response.data.data) : updateCategories(response.data.data)
      );
      onSuccess();
    } else {
      displayWarning(`Could not ${isEditing ? 'Save Changes' : 'Create Category'}`);
    }

    setLoading(false);
  };
  const handleFileInput = (e: ChangeEvent<HTMLInputElement>) => {
    let file = e.target.files && e.target.files[0];
    setFile(file);
  };

  const [thumbnail, setThumbnail] = useState<string>();

  const handleThumbnail = async () => {
    if (currentTHumbnail) {
      let url = await _getFileUrl(currentTHumbnail?.key);
      return setThumbnail(url);
    }
  };

  useEffect(() => {
    handleThumbnail();
  }, []);

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className=" bg-white  rounded-md p-6 mt-24 w-4/5 sm:w-3/5 lg:w-1/2 xl:w-1/3 ">
      <div className=" flex mb-6 items-center justify-between">
        <p className=" text-xl font-semibold ">Create Category</p>

        <span onClick={closer} className=" hover:cursor-pointer text-bash ">
          Close
        </span>
      </div>

      <div className=" flex items-center gap-x-4 ">
        <div
          onClick={() => {
            inputRef.current?.click();
          }}
          className=" relative">
          <div className=" bg-ashShade-3 rounded-full w-20 h-20 flex items-center justify-center ">
            {file || thumbnail ? (
              <img
                src={file ? convertFiletoUrl(file) : thumbnail}
                className="w-20 h-20 object-cover rounded-full"
              />
            ) : (
              <TbPhotoFilled color="#9099A8" size={40} />
            )}
          </div>

          <span className=" absolute p-1 top-0 right-0 rounded-full z-10 bg-bblack-1">
            {React.createElement(isEditing ? TbEdit : TbPlus, {
              color: 'white'
            })}
          </span>
          <input onChange={handleFileInput} ref={inputRef} className="hidden" type="file" />
        </div>
        <span className=" font-semibold text-sm">Upload Category Thumbnail</span>
      </div>

      <InputField
        value={values.name}
        name="name"
        onChange={handleChange}
        label="Category Title"
        placeholder="Title"
      />
      <InputField
        value={values.description}
        name="description"
        onChange={handleChange}
        label="Description"
        placeholder="description"
      />

      <div className=" flex mt-6 items-center justify-end gap-x-3">
        <Button onClick={closer} type="secondary" text="Cancel" />
        <Button
          isLoading={loading}
          onClick={() => {
            handleSubmit();
          }}
          type={isValid ? 'primary' : 'muted'}
          text={isEditing ? 'Save Changes' : 'Create Category'}
        />
      </div>
    </div>
  );
};

export default CategoryForm;
