import React, { useEffect, useRef, useState } from 'react';
import { FaEllipsisVertical } from 'react-icons/fa6';
import { TutorialCategoryType } from '../types';
import { convertToProper, _getFileUrl } from 'components/shared/utils';
import { useNavigate } from 'react-router-dom';
import { Option } from 'pages/projects/Clusters/BuildingTypes';
import { TbEdit } from 'react-icons/tb';
import { useClickOutSideComponent } from 'components/projects/Team/Views/Components/OnScreen';
import useRole from 'Hooks/useRole';

interface GroupCardProps {
  title: string;
}

const GroupCard = ({
  name,
  categoryImage,
  _id,
  handleEdit
}: TutorialCategoryType & { handleEdit: () => void }) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState(false);
  const [url, setUrl] = useState<string>();
  const navigate = useNavigate();
  let { isPortfolioManager } = useRole();

  useEffect(() => {
    const handleFetch = async () => {
      setLoading(true);
      if (categoryImage.key) {
        let _url = await _getFileUrl(categoryImage.key);
        if (_url) {
          setUrl(_url);
        }
      }
      setLoading(false);
    };

    handleFetch();
  }, [categoryImage]);
  let x = Math.round(Math.random());
  const optionsRef = useRef<HTMLDivElement>(null);

  useClickOutSideComponent(optionsRef, () => setOptions(false));
  return (
    <div className="w-full cursor-pointer ">
      <div
        onClick={(e) => {
          console.log(e);

          navigate(_id);
        }}
        className={` w-full ${
          loading ? 'animate-pulse_fast' : ''
        } rounded-md bg-ashShade-4  py-8 flex overflow-hidden items-center justify-center h-40 max-h-40`}>
        {url ? (
          <img
            className="w-full h-40 object-cover  "
            onError={() => {
              setUrl(undefined);
            }}
            src={url}
            alt=""
          />
        ) : (
          !loading && (
            <div className={` w-24 h-24 bg-ashShade-2  ${x < 1 ? ' rounded-full ' : ''} `}></div>
          )
        )}
      </div>
      <div
        onClick={(e) => e.stopPropagation()}
        className=" mt-2 relative   w-full flex items-center justify-between">
        <span className=" font-semibold w-4/5">{convertToProper(name)}</span>
        <div className="flex flex-col">
          {isPortfolioManager && (
            <div
              onClick={() => {
                setOptions(!options);
              }}
              className="rounded-full p-2 hover:bg-ashShade-0 ">
              <FaEllipsisVertical color="#9099A8" className="" />
            </div>
          )}
        </div>

        {options && (
          <div
            ref={optionsRef}
            className="absolute bg-white z-10 right-2 p-2 w-24 rounded-md top-0">
            <Option text="Edit" onClick={handleEdit} icon={TbEdit} />
          </div>
        )}
      </div>
    </div>
  );
};

export default GroupCard;
