import { postForm } from 'apis/postForm';
import AccessComponent from 'components/projects/documents/document-repo/AccessComponent';
import RoleType from 'pages/projects/account/Views/manage/RoleType';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { updateRecord } from 'store/slices/bookKeepingSlice';
import { displayError } from 'Utils';
import { Book, BookAccess } from './types';
import useFiles from 'components/projects/documents/document-repo/useFiles';

interface BookAccessModalProps {
  _id: string | null;
  closer: () => void;
}

type RefetchOptions = {
  refetchFiles?: boolean;
  updateStore?: boolean;
};

const defaultRefetchOptions: RefetchOptions = {
  refetchFiles: true,
  updateStore: true
};

const BookAccessModal = ({ _id, closer }: BookAccessModalProps) => {
  let { data } = useAppSelector((m) => m.bookKeeping);
  let { data: _members, loading } = useAppSelector((m) => m.team);
  let { fileFolders } = useAppSelector((m) => m.folder);
  let user = useAppSelector((m) => m.user);
  let {reloadFiles } = useFiles(true);
  let dispatch = useDispatch();
  const current = useMemo(() => {
    return data.find((m) => m._id === _id);
  }, [data]);

  let selectedMembers = useMemo(() => {
    let ownerId = '';
    let isCoOwner = false;

    let list =
      current?.access.map((m) => {
        if (m.roleType === 'owner') ownerId = m.userId;
        if (m.roleType === 'co-owner' && m.userId === user._id) {
          isCoOwner = true;
        }
        return m.userId;
      }) || [];
    let isDisplay = ownerId !== user._id && !isCoOwner;
    return { list, ownerId, isDisplay, isCoOwner };
  }, [current]);

  let Members = useMemo(() => {
    if (!loading) {
      let all = Object.values(_members) || [];
      return all.filter((m) => m?._id && m?.name?.toLowerCase() !== 'general');
    }
    return [];
  }, [_members, loading, current]);



  const handleAccessGrant = async (
    user: Record<string, any>,
    options: RefetchOptions = defaultRefetchOptions
  ) => {
    let { refetchFiles, updateStore } = options;
    let { e, response } = await postForm('patch', 'financials/bookkeeping/add-access', {
      bookId: current?._id,
      access: [
        {
          userId: user._id,
          role: user.role,
          roleType: 'member',
          accessType: 'viewer'
        }
      ]
    });

    if (e) {
      displayError(e?.message);
    } else {
      if (updateStore) {
        dispatch(updateRecord(response.data.data));
      }

      if (refetchFiles) {
        reloadFiles();
      }

      return response.data.data;
    }
  };

  const onRemoveAccess = async (
    user: Record<string, any>,
    options: RefetchOptions = defaultRefetchOptions
  ) => {
    let { refetchFiles, updateStore } = options;
    let { e, response } = await postForm('patch', 'financials/bookkeeping/remove-access', {
      bookId: current?._id,
      userId: user._id
    });

    if (e) {
      displayError(e?.message);
    } else {
      if (updateStore) {
        dispatch(updateRecord(response.data.data));
      }

      if (refetchFiles) {
        reloadFiles();
      }

      return response.data.data;
    }
  };

  const RemoveAccessWithUserId = async (
    id: string,
    options: RefetchOptions = defaultRefetchOptions
  ) => {
    let { refetchFiles, updateStore } = options;
    let { e, response } = await postForm('patch', 'financials/bookkeeping/remove-access', {
      bookId: current?._id,
      userId: id
    });

    if (e) {
      displayError(e?.message);
    } else {
      if (updateStore) {
        dispatch(updateRecord(response.data.data));
      }

      if (refetchFiles) {
        reloadFiles();
      }
      return response.data.data;
    }
  };

  const onChange = (type: 'role' | 'access') => async (user: Record<string, any>, val: string) => {
    let userAccess = current?.access?.find((m) => m.userId === user._id);
    if (userAccess) {
      let data = {
        bookId: current?._id,
        ...(userAccess as Partial<BookAccess>)
      };
      const isCoOwner = val === 'co-owner';

      if (isCoOwner) {
        data.accessType = 'editor';
        data.roleType = 'co-owner';
      } else {
        data.accessType = val;
        data.roleType = 'member';
      }

      delete data?._id;
      delete data?.role;
      let { e, response } = await postForm('patch', 'financials/bookkeeping/update-access', data);

      if (e) {
        displayError(e?.message);
      } else {
        dispatch(updateRecord(response.data.data));
      }
    }
  };

  useEffect(() => {
    let removeOdds = async () => {
      const memberIDs = Members.filter((m) => m).map((m) => m?._id);
      let odds = selectedMembers.list.filter((m) => !memberIDs.includes(m));
      for (let i = 0; i < odds.length; i++) {
        await RemoveAccessWithUserId(odds[i], { refetchFiles: false, updateStore: false });
      }
    };

    removeOdds();
  }, [Members]);

  const onToggleGeneralAccess = async () => {
    if (selectedMembers.list.length > 1) {
      let unwantedMembers = Members.filter((m) => {
        return (
          m?._id &&
          m?._id !== selectedMembers.ownerId &&
          !selectedMembers.isCoOwner &&
          selectedMembers.list.includes(m?._id)
        );
      });

      for (let i = 0; i < unwantedMembers.length; i++) {
        await onRemoveAccess(unwantedMembers[i], {
          refetchFiles: false,
          updateStore: true
        });
      }
    } else {
      let newMembers = Members.filter((m) => m._id !== selectedMembers.ownerId);

      for (let i = 0; i < newMembers.length; i++) {
        await handleAccessGrant(newMembers[i], {
          refetchFiles: false,
          updateStore: true
        });
      }
    }
    reloadFiles();
  };

  return (
    <AccessComponent
      onRemoveUserAccess={onRemoveAccess}
      onGrantUserAccess={handleAccessGrant}
      MemberAccessList={current?.access}
      title={current?.name + ' Access'}
      isDisplay={selectedMembers.isDisplay}
      Members={Members}
      selectedUsers={selectedMembers.list}
      ownerId={selectedMembers.ownerId}
      closer={closer}
      onChangeAccessType={onChange('access')}
      accessTypeOptions={['viewer', 'editor', 'co-owner']}
      onToggleGeneralAccess={onToggleGeneralAccess}
    />
  );
};

export default BookAccessModal;
