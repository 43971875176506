import React, { useState, useEffect, useCallback, useMemo } from 'react';
import SuperModal from 'components/shared/SuperModal';
import { TranchModalProps, TranchProps } from './types';
import { postForm } from 'apis/postForm';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { displayError, displaySuccess, displayWarning } from 'Utils';
import NumberInput, { NumberInputWithLabel } from 'components/shared/NumberInput';
import {
  addFinanceDisbursement,
  closeModal,
  DisbursementItem,
  TDisbursements,
  updateField,
  updateFinanceDisbursement
} from 'store/slices/financeSlice';
import { useFormik } from 'formik';
import * as yup from 'yup';
import SelectDate from 'components/shared/SelectDate';
import Button from 'components/shared/Button';
import { TbPlus, TbTrash } from 'react-icons/tb';
import InputField from 'components/shared/ProjectMoneyInputField/InputField';
import { reparse } from 'helpers';
import { RequiredStringSchema } from 'yup/lib/string';
import { AnyObject } from 'yup/lib/types';

let initialValue: {
  paymentDate: Date;
  contractorAmount: number;
  materialAmount: number;
  adminAmount: number;
  disbursementItems: { name: string; amount: number }[];
  amount: number;
  dueDate: Date;
} = {
  paymentDate: new Date(),
  contractorAmount: 0,
  materialAmount: 0,
  adminAmount: 0,
  disbursementItems: [],
  amount: 0,
  dueDate: new Date()
};
let numberSchema = yup.number();
const createInitialValue = (tranch: TDisbursements) => {
  let _tranch = reparse(tranch);
  let _initialValue = { _tranch };

  let { paymentDate, dueDate, amount, adminAmount, materialAmount, contractorAmount } = _tranch;

  let ret = {
    paymentDate: new Date(paymentDate),
    contractorAmount,
    materialAmount,
    adminAmount,
    disbursementItems: [..._tranch.disbursementItems],
    amount,
    dueDate: new Date(dueDate)
  };

  return ret;
};

const TranchModal = ({ closer, value }: TranchModalProps) => {
  const [fetching, setFetching] = useState(false);
  let { data, modal } = useAppSelector((m) => m.finance);
  const [errorList, setErrorList] = useState<{ name?: string; amount?: string }[]>([]);
  let dispatch = useAppDispatch();
  let { errors, values, setFieldValue, handleChange, handleSubmit, touched, resetForm, isValid } =
    useFormik({
      initialValues: value ? createInitialValue(value) : initialValue,
      onSubmit: (data) => {
        handleSubmission(data);
      },
      validationSchema: yup.object({
        paymentDate: yup.string().required(),
        dueDate: yup.string().required(),
        contractorAmount: numberSchema.notRequired(),
        materialAmount: numberSchema.notRequired(),
        adminAmount: numberSchema.notRequired(),
        disbursementItems: yup.array().of(
          yup.object({
            name: yup.string().required('This is a required field'),
            amount: numberSchema
          })
        ),
        amount: numberSchema
      }),
      validateOnBlur: true
    });

  const handleSubmission = async (_data: Record<string, any>) => {
    if (_data.amount === 0) {
      displayWarning('Total amount cannot be zero');
      return;
    }
    setFetching(true);
    let body: Record<string, any> = { ..._data, financialsId: data?._id };
    if (body.disbursementItems.length < 1) {
      delete body.disbursementItems;
    }
    if (value) {
      //find the difference between current items and the new details
      const toBeDeleted = [];
      const toBeUpdated = [];
      const toBeCreated = body?.disbursementItems?.filter((m: DisbursementItem) => !m._id) || [];
      let { disbursementItems } = value;

      for (let disbursementItem of disbursementItems) {
        let isExisting = body?.disbursementItems?.find(
          (m: DisbursementItem) => m._id === disbursementItem._id
        );
        if (!isExisting) {
          toBeDeleted.push(disbursementItem);
        } else {
          if (
            isExisting.amount !== disbursementItem.amount ||
            isExisting.name !== disbursementItem.name
          ) {
            toBeUpdated.push(isExisting);
          }
        }
      }

      //make the api call to effect these changes

      await Promise.all(
        toBeDeleted.map((m) =>
          postForm('patch', 'financials/disbursement/item/remove', {
            disbursementId: value._id,
            disbursementItemId: m._id
          })
        )
      );

      await Promise.all(
        toBeUpdated?.map((m: DisbursementItem) =>
          postForm('patch', 'financials/disbursement/item/update', {
            amount: m.amount,
            disbursementId: value._id,
            disbursementItemId: m._id,
            name: m.name
          })
        )
      );
      await postForm('patch', 'financials/disbursement/item/add', {
        // amount: m.amount,
        disbursementId: value._id,
        items: toBeCreated?.map((m: { name: string; amount: number }) => ({
          name: m.name,
          amount: m.amount
        }))
        // name: m.name
      });
      // await Promise.all(
      //   toBeCreated?.map((m: DisbursementItem) =>
      //     postForm('patch', 'financials/disbursement/item/add', {
      //       amount: m.amount,
      //       disbursementId: value._id,
      //       name: m.name
      //     })
      //   )
      // );

      //make changes in the actual disbursement

      let { amount, paymentDate, dueDate, adminAmount, contractorAmount, materialAmount } = body;

      let { e, response } = await postForm('patch', 'financials/disbursement/update', {
        disbursementId: value._id,
        paymentDate,
        dueDate,
        adminAmount,
        contractorAmount,
        materialAmount
      });

      if (response) {
        dispatch(updateFinanceDisbursement(response.data.data));
        displaySuccess('Disbursement Tranch updated successfully');
        closer();
      } else {
        displayError(e?.message || 'could not update disbursement tranch');
      }
      //update store
    } else {
      delete body?.amount;
      let { response, e } = await postForm('patch', `financials/disbursement/add`, body);
      if (response) {
        dispatch(addFinanceDisbursement(response.data.data));
        displaySuccess('Disbursement Added Successfully');
        closer();
      } else {
        displayWarning(e?.message || 'Could not add tranch');
      }
    }

    setFetching(false);
  };

  useEffect(() => {
    let { contractorAmount, materialAmount, adminAmount } = values;
    let totalAmount = contractorAmount + materialAmount + adminAmount;
    for (let i of values.disbursementItems) {
      totalAmount += i.amount;
    }
    setFieldValue('amount', totalAmount);
  }, [
    values.adminAmount,
    values.contractorAmount,
    values.materialAmount,
    values.disbursementItems
  ]);

  useEffect(() => {
    setFieldValue('dueDate', values.paymentDate);
  }, [values.paymentDate]);

  const listErrors = async () => {
    let errors: { name?: string; amount?: string }[] = [];
    let stringSchema = yup.string().required('This is a required field');
    for (let obj of values.disbursementItems) {
      let schema = yup.object().shape({
        name: stringSchema,
        amount: numberSchema
      });

      const validate = async (
        schema:
          | RequiredStringSchema<string | undefined, AnyObject>
          | yup.NumberSchema<number | undefined, AnyObject, number | undefined>,
        value: string | number
      ) => {
        try {
          let res = await schema.validate(value);
          return undefined;
        } catch (error: any) {
          return error.errors;
        }
      };

      errors.push({
        name: await validate(stringSchema, obj.name),
        amount: await validate(numberSchema.min(0.1, 'This field cannot be zero'), obj.amount)
      });
    }

    setErrorList(errors);
  };

  useEffect(() => {
    listErrors();
  }, [values.disbursementItems]);

  return (
    <SuperModal
      closer={closer}
      classes="bg-black bg-opacity-80 flex justify-center overflow-y-auto pb-3 ">
      <span
        onClick={(e) => {
          e.stopPropagation();
        }}
        className=" bg-white h-fit mt-[10%] rounded-lg w-full max-w-[484px] p-4">
        <div className="flex mb-6 hover:cursor-pointer items-center justify-between">
          <span className="font-Medium text-xl">Create Tranch</span>
          <span onClick={closer} className="hover:underline text-bash text-sm">
            Close
          </span>
        </div>

        <SelectDate
          className=" mt-8"
          error={((touched.paymentDate && errors?.paymentDate) || '') as string}
          placeholder="dd/mm/yy"
          wrapperClassName=" !border-ashShade-4 "
          minDate={new Date(0)}
          // maxDate={new Date()}
          initialValue={values?.paymentDate ? new Date(values.paymentDate) : new Date()}
          value={values.paymentDate}
          label="Payment Date"
          onChange={(e) => {
            console.log(e);
            if (e) {
              setFieldValue('paymentDate', e.toISOString());
              // setFieldValue('date', e.toISOString());
            }
          }}
        />

        <NumberInputWithLabel
          error={((touched?.contractorAmount && errors.contractorAmount) || '') as string}
          placeholder={`eg. 25,000`}
          label="Contractor Amount"
          labelClassName=" text-sm "
          value={values.contractorAmount}
          setter={(n: number) => setFieldValue('contractorAmount', n)}
          className="w-full font-Medium text-bblack-0  outline-bblue border mt-2  rounded-md px-4 py-2"
          containerClass=" mb-6"
        />
        <NumberInputWithLabel
          error={(touched.materialAmount && (errors.materialAmount || '')) as string}
          placeholder={`eg. 25,000`}
          label="Material's Amount"
          labelClassName=" text-sm "
          value={values.materialAmount}
          setter={(n: number) => setFieldValue('materialAmount', n)}
          className="w-full font-Medium text-bblack-0 outline-bblue border mt-2 rounded-md px-4 py-2"
          containerClass=" mb-6"
        />

        <NumberInputWithLabel
          error={(touched.adminAmount && (errors.adminAmount || '')) as string}
          placeholder={`eg. 25,000`}
          label="Admin Amount"
          labelClassName=" text-sm "
          value={values.adminAmount}
          setter={(n: number) => setFieldValue('adminAmount', n)}
          className="w-full font-Medium text-bblack-0  outline-bblue border mt-2  rounded-md px-4 py-2"
        />
        {values.disbursementItems.map((m, i) => (
          <div className="flex items-center mt-2 gap-x-2">
            <div className="  flex flex-col md:flex-row gap-x-2 flex-1 ">
              <InputField
                ContainerClassName=" !my-0 w-1/2 "
                error={errorList[i]?.name}
                labelClassName=" !mt-0"
                label="Name"
                value={values.disbursementItems[i].name}
                onChange={(e) => {
                  let disbursementItems = [...values.disbursementItems];
                  disbursementItems[i].name = e.target.value;
                  setFieldValue('disbursementItems', disbursementItems);
                }}
              />

              <NumberInputWithLabel
                containerClass=""
                error={errorList[i]?.amount}
                placeholder={`eg. 25,000`}
                label="Amount"
                labelClassName=" text-sm "
                value={(values.disbursementItems[i].amount as number) || 0}
                setter={(n: number) => {
                  let _disbursementItems = [...values.disbursementItems];
                  _disbursementItems[i].amount = n;
                  setFieldValue('disbursementItems', _disbursementItems);
                }}
                className=" font-Medium text-bblack-0  outline-bblue border mt-2  rounded-md px-4 py-2  "
              />
            </div>
            <span
              onClick={() => {
                let disbursementItems = [...values.disbursementItems];
                disbursementItems = disbursementItems.filter((_, j) => j !== i);

                setFieldValue('disbursementItems', disbursementItems);
              }}
              className=" hover:bg-ashShade-0 p-2 mt-2 rounded-full">
              <TbTrash color="#B63434" />
            </span>
          </div>
        ))}

        <p
          onClick={() => {
            setFieldValue('disbursementItems', [
              ...values.disbursementItems,
              {
                name: '',
                amount: 0
              }
            ]);
          }}
          className=" hover:underline cursor-pointer text-bblue flex mt-2 items-center">
          <TbPlus className=" mr-4" /> Add field
        </p>
        <div className="flex mt-4 items-center justify-end">
          <Button text="Cancel" type="secondary" onClick={closer} />
          <Button
            type="primary"
            isLoading={fetching}
            onClick={() => handleSubmit()}
            className=" ml-4"
            text={`${value ? 'Update' : 'Create'} Tranch`}
          />
        </div>
      </span>
    </SuperModal>
  );
};

export default TranchModal;
