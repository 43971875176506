import React, { useContext, useEffect, useState } from 'react';
import { postForm } from 'apis/postForm';
import { StoreContext } from 'context';
import { filterForMedia } from 'components/projects/photos/pics';
import { GetDownloadSignedUrls } from 'apis/AwsFiles';
import mime from 'mime';
import axios from 'axios';
import { displayError, displaySuccess, displayWarning, displayInfo } from 'Utils';
import { useAppSelector } from 'store/hooks';
import useFiles from 'components/projects/documents/document-repo/useFiles';
import { LatestDoc, NewDoc } from 'components/projects/documents/document-repo/SingleDocModal';
import { removeemptyFields } from 'pages/projectform/utils';

type ProjectImage = { url: string; key: string; ContentType?: string };

const useProjectImages = (folder?: string, deps: any[] = []) => {
  const { data, selectedProjectIndex, activeProject } = useContext(StoreContext);
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState<ProjectImage[]>([]);
  const { files, fileFolders } = useAppSelector((m) => m.folder);
  const fetchImages = async () => {
    const _files = filterForMedia(
      [...files].sort((a, b) => {
        let aTime = new Date(a.timestamp).getTime();
        let bTime = new Date(b.timestamp).getTime();
        return bTime - aTime;
      })
    ).filter((m) => {
      let asFile = m as LatestDoc;
      if (!folder) return true;
      if (folder) {
        if (folder)
          return typeof asFile.folder === 'string'
            ? asFile.folder === folder
            : asFile?.folder?.name === folder;
      }
    });

    let urls = await Promise.all(_files.map((m) => GetDownloadSignedUrls(m.S3Key)));
    let ims = urls
      .filter((m) => m.data)
      .map((m) => {
        return {
          ...m?.data,
          ContentType: files.find((k) => m.data.key === k.S3Key)?.ContentType
        };
      }) as unknown as ProjectImage[];
    setImages((_) => ims);

    setLoading(false);
  };
  useEffect(() => {
    fetchImages();
  }, [activeProject, fileFolders, files]);
  return { loading, images, fetchImages };
};

const useUploader = (files: File[], deps: any[]) => {
  const [loading, setLoading] = useState(true);
  const { data, selectedProjectIndex } = useContext(StoreContext);

  useEffect(() => {
    if (files.length > 0) {
      uploadAll();
    }
  }, [selectedProjectIndex, files]);

  const uploadAll = async () => {
    await Promise.all(files.map((m) => upload(m)));
  };

  const upload = async (file: any) => {
    setLoading(true);
    let { response, e } = await postForm('post', 'files/upload', {
      Bucket: 'bnkle-professional-docs',
      ContentType: file.type,
      Extension: mime.getExtension(file.type) || ''
    });
    if (response) {
      try {
        let form = new FormData();
        form.append(file.name, file);
        await axios({
          method: 'put',
          url: response?.data?.data?.url,
          data: file,
          headers: { 'Content-Type': file.type }
        });

        try {
          let res = await postForm('post', 'files/add', {
            Bucket: 'bnkle-professional-docs',
            ContentType: file.type,
            S3Key: response?.data?.data?.key,
            project: data[selectedProjectIndex]._id,
            alias: file.name,
            folder: 'media'
          });
          if (res.e) {
            displayError(`Upload for ${file.name} Unsuccessful`);
          } else {
            // setProgress(100);
            displaySuccess('Upload Successful');
            // dispatch(
            //   changeFieldValue({
            //     all: [res.response.data.data, ...folder.files],
            //   })
            // );
          }
        } catch (error) {
          Promise.reject(error);
        }
      } catch (error) {
        displayWarning(`Upload for ${file.name} Unsuccessful`);
      }
    }
    setLoading(false);
    return { response, e };
  };

  return { loading };
};

const uploadAll = async (files: File[], projectId: string, folder: string = 'media') => {
  return await Promise.all(files.map((m) => upload(m, projectId, folder)));
};

const upload = async (file: File, projectId: string, folder: string) => {
  let { response, e } = await postForm('post', 'files/upload', {
    Bucket: 'bnkle-professional-docs',
    ContentType: file.type,
    Extension: mime.getExtension(file.type) || ''
  });
  if (response) {
    try {
      let form = new FormData();
      form.append(file.name, file);
      await axios({
        method: 'put',
        url: response?.data?.data?.url,
        data: file,
        headers: { 'Content-Type': file.type }
      });

      try {
        let res = await postForm('post', 'files/add', {
          Bucket: 'bnkle-professional-docs',
          ContentType: file.type,
          S3Key: response?.data?.data?.key,
          project: projectId,
          alias: file.name,
          folder: folder
        });
        if (res.e) {
          e = res.e;
          displayError(`Upload for ${file.name} Unsuccessful`);
        } else {
          displaySuccess('Upload Successful');
        }
      } catch (error) {
        Promise.reject(error);
      }
    } catch (error) {
      displayWarning(`Upload for ${file.name} Unsuccessful`);
    }
  }
  return { e, response };
};

const uploadWithOptions = async (file: File, projectId: string, options: Record<string, any>) => {
  let { response, e } = await postForm('post', 'files/upload', {
    Bucket: 'bnkle-professional-docs',
    ContentType: file.type,
    Extension: mime.getExtension(file.type) || ''
  });
  if (response) {
    try {
      let form = new FormData();
      form.append(file.name, file);
      await axios({
        method: 'put',
        url: response?.data?.data?.url,
        data: file,
        headers: { 'Content-Type': file.type }
      });

      try {
        let res = await postForm(
          'post',
          'files/add',
          removeemptyFields({
            Bucket: 'bnkle-professional-docs',
            ContentType: file.type,
            S3Key: response?.data?.data?.key,
            project: projectId,
            alias: file.name,
            ...options
          })
        );
        if (res.e) {
          e = res.e;
          displayError(`Upload for ${file.name} Unsuccessful`);
        } else {
          // setProgress(100);

          displaySuccess('Upload Successful');
          // dispatch(
          //   changeFieldValue({
          //     all: [res.response.data.data, ...folder.files],
          //   })
          // );
        }
      } catch (error) {
        Promise.reject(error);
      }
    } catch (error) {
      displayWarning(`Upload for ${file.name} Unsuccessful`);
    }
  }
  return { e, response, file };
};

const uploadAllWithOptions = async (
  files: File[],
  projectId: string,
  options: Record<string, any>
) => {
  return await Promise.all(files.map((m) => uploadWithOptions(m, projectId, options)));
};

const uploadWithoutSaving = async (file: File) => {
  let data = {
    Bucket: 'bnkle-professional-docs',
    ContentType: file.type,
    Extension: mime.getExtension(file.type) || ''
  };
  let { response, e } = await postForm('post', 'files/upload', data);
  let Err, res;
  if (response) {
    try {
      res = await axios({
        method: 'put',
        url: response?.data?.data?.url,
        data: file,
        headers: { 'Content-Type': file.type }
      });
    } catch (error) {
      Err = error;
    }
  }

  return {
    response: res
      ? {
          S3Key: response.data.data.key,
          ...data
        }
      : undefined,
    e: Err ?? e,
    file
  };
};

const uploadAllWithoutSaving = async (files: File[]) => {
  return Promise.all(files.map((m) => uploadWithoutSaving(m)));
};

const uploadFile = async (file: File, projectId: string, folder: string) => {
  let { response, e } = await postForm('post', 'files/upload', {
    Bucket: 'bnkle-professional-docs',
    ContentType: file.type,
    Extension: mime.getExtension(file.type) || ''
  });
  if (response) {
    try {
      let form = new FormData();
      form.append(file.name, file);
      await axios({
        method: 'put',
        url: response?.data?.data?.url,
        data: file,
        headers: { 'Content-Type': file.type }
      });

      try {
        let res = await postForm('post', 'files/add', {
          Bucket: 'bnkle-professional-docs',
          ContentType: file.type,
          S3Key: response?.data?.data?.key,
          project: projectId,
          alias: file.name,
          folder: folder
        });
        if (res.e) {
          e = res.e;
          displayError(`Upload for ${file.name} Unsuccessful`);
        } else {
          response = res.response;
          displaySuccess('Upload Successful');
        }
      } catch (error) {
        console.log(error);
        Promise.reject(error);
      }
    } catch (error) {
      console.log(error);
      displayWarning(`Upload for ${file.name} Unsuccessful`);
    }
  }
  return { e, response, file };
};

const uploadMultipleFiles = async (files: File[], projectId: string, folder = 'bids') => {
  let uploads = await Promise.all(files?.map((m) => uploadFile(m, projectId, folder)) || []);
  let docs = [];
  for (let upload of uploads) {
    let file = upload.file;
    docs.push({
      name: file.name,
      key: upload.response.data?.data?.S3Key,
      meta: {
        size: file.size,
        type: file.type,
        name: file.name
      }
    });
  }

  return docs;
};

export {
  useUploader,
  uploadAll,
  upload,
  uploadFile,
  uploadMultipleFiles,
  uploadAllWithOptions,
  uploadWithOptions,
  uploadAllWithoutSaving,
  uploadWithoutSaving
};

export default useProjectImages;
