import React, { useContext, useEffect, useMemo } from 'react';
import emptygallery from 'assets/emptygallery.svg';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import Gallery from './Gallery';
import Updates from './Updates';
import Budget from './Budget';
import Tasks from './Tasks';
import Photos from './Photos';
import { StoreContext } from 'context';
import { getFolderFiles } from 'components/projects/documents/document-repo/useFiles';
import { changeFieldValue } from 'store/slices/folderSlice';
import { LatestDoc } from 'components/projects/documents/document-repo/SingleDocModal';
import { groupFiles } from 'components/projects/documents/document-repo';

export const dashboardFolders = ['media', 'presentation'];

interface Props {}
const Index = () => {
  let members = useAppSelector((m) => m.team);
  let { selectedProjectIndex } = useContext(StoreContext);
  let fileState = useAppSelector((m) => m.folder);
  let dispatch = useAppDispatch();


  const fetchMediaAndGallery = async () => {
    let mediaAndPresentation = fileState.fileFolders.filter((m) =>
      dashboardFolders.includes(m.name)
    );

    let res = await Promise.all(mediaAndPresentation.map((m) => getFolderFiles(m._id)));
    let dashboardFiles = res
      .filter((m) => (m.response ? true : false))
      .map((m) => m.response.data.data.files)
      .flat() as LatestDoc[];

    let acc: Record<string, LatestDoc> = {};

    fileState.files.map((m) => {
      acc[m._id as string] = m;
    });

    let newFiles = [...fileState.files];
    dashboardFiles.forEach((m) => {
      if (!acc[m._id as string]) newFiles.push(m);
    });

    dispatch(
      changeFieldValue({
        files: newFiles,
        folder: groupFiles(newFiles)
      })
    );
  };
  useEffect(() => {
    fetchMediaAndGallery();
  }, [fileState.fileFolders]);

  let component = useMemo(() => {
    return (
      <div className=" w-full h-full overflow-y-auto pb-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 items-center w-full gap-5 ">
          <Gallery />
          <Updates />
        </div>
        <div className="w-full my-8">
          <Budget />
        </div>
        <div className="grid grid-cols-1 max-h-[50%] lg:grid-cols-2  gap-5 ">
          <Tasks />
          <Photos />
        </div>
      </div>
    );
  }, [selectedProjectIndex]);
  return component;
};

interface NoContentProps {
  title: string;
  titleClass?: string;
  imageClass?: string;
  containerClass?: string;
}

const NoContentDashBoard = ({ title, imageClass, titleClass, containerClass }: NoContentProps) => {
  return (
    <div className={'w-full ' + containerClass}>
      <img src={emptygallery} className={imageClass} />
      <p className={titleClass}>{title}</p>
    </div>
  );
};

export { NoContentDashBoard };

export default Index;
